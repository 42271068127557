<script lang="ts">
  import type { LogoGroup } from '$lib/contentful/models/logo-group';
  import Box from '$lib/layout/grid/box.svelte';
  import Grid from '$lib/layout/grid/grid.svelte';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import Text from '$lib/components/text/text.svelte';
  import Slot from '$lib/components/slot.svelte';

  export let content: LogoGroup | undefined;
  export let wrapInGrid: boolean = true;
</script>

{#if content}
  {@const { title, background, logos, entityId, display, eyebrow } = content}
  {#if display === 'scrolling'}
    <div class="logo-marquee {background}">
      <div class="marquee-group">
        {#each logos as logo}
          <ContentfulImage
            fieldId="logo"
            {entityId}
            class="h-6"
            height={24}
            src={logo.url}
            alt={logo.title}
          />
        {/each}
      </div>
      <div aria-hidden="true" class="marquee-group">
        {#each content.logos as logo}
          <ContentfulImage
            fieldId="logo"
            {entityId}
            class="h-6"
            height={24}
            src={logo.url}
            alt={logo.title}
          />
        {/each}
      </div>
    </div>
  {:else}
    <svelte:component this={wrapInGrid ? Grid : Slot} as="div" layout="columns">
      <Box as="div" class="flex flex-col gap-6 py-10">
        <div class="flex justify-center">
          {#if eyebrow}
            <Text as="h3" class="text-mint" variant="eyebrow-16">
              {eyebrow}
            </Text>
          {/if}
          {#if title}
            <Text as="p" variant="body-24">
              {title}
            </Text>
          {/if}
        </div>
        <div class="flex justify-evenly py-10 flex-wrap gap-8">
          {#each logos as logo}
            <ContentfulImage
              fieldId="logo"
              {entityId}
              class="h-12"
              height={48}
              src={logo.url}
              alt={logo.title}
            />
          {/each}
        </div>
      </Box>
    </svelte:component>
  {/if}
{/if}

<style lang="postcss">
  .logo-marquee {
    @apply py-10 flex overflow-hidden select-none gap-16;
  }

  .logo-marquee.light {
    @apply bg-white;
  }

  .marquee-group {
    @apply flex shrink-0 items-center justify-around gap-16 min-w-full animate-logo-marquee;
  }
</style>
